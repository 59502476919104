const IntroSection = () => {
    return (
        <section id="intro">
            <p className="name">Welcome to
                <span>Catch Rupee</span>
            </p>
            <h2>
                Connecting Businesses with Creators
            </h2>
            <p>At DubbiGo, we connect city businesses with local influencers and creators to effectively promote brands
                and reach target audiences.</p>
            <p>Explore our <a href="#services">services</a> or join our <a href="#join">network</a> to grow your
                business!</p>
        </section>
    );
};

export default IntroSection;