import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer" id="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h2>Dubbi Go</h2>
                    <p>Connecting Businesses with Creators</p>
                </div>


                <div className="footer-section">
                    <h3>Contact Us</h3>
                    <p>
                        Email: <a href="mailto:info@dubbigo.com"
                                  className="text-blue-500 hover:underline">info@dubbigo.com</a>
                    </p>
                    {/*<p>
                        Phone: <a href="tel:+919827787080" className="text-blue-500 hover:underline">+91-9827787080</a>
                    </p>*/}
                </div>

                <div className="footer-section">
                    <h3>Follow Us</h3>
                    <div className="social-links">
                        {/*<a href="https://www.linkedin.com/company/dubbigo" aria-label="LinkedIn">
                            <i className="fab fa-linkedin"></i>
                        </a>*/}
                        {/*<a href="https://www.youtube.com/@dubbigo"  aria-label="YouTube">
                            <i className="fab fa-youtube"></i>
                        </a>*/}
                        <a href="https://www.instagram.com/dubbigo.ai"  aria-label="Instagram">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 DubbiGo. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;